import { library, dom } from '@fortawesome/fontawesome-svg-core'

// Solid
import {
  faAd, faArchive, faArrowsAlt, faArrowRight, faBallot, faBookmark,
  faCalendarAlt, faCaretLeft, faCaretRight, faCheck, faCog, faCrown, faFileCsv, faFileSpreadsheet, faFrown,
  faGraduationCap, faLink, faListOl, faMapSigns, faPencil, faPennant, faPeopleArrows, faPlus, faPrint,
  faPuzzlePiece, faQuestion, faQuestionCircle, faSmile, faSpinner, faStar, faStarHalfAlt,
  faStopwatch, faSwords, faSync, faTasks, faTimes, faUndo, faUnlink, faUser, faUserSlash, faUsersCrown,
  faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileImage, faFile
} from '@fortawesome/pro-solid-svg-icons'

// Regular
import {
  faAngleDoubleLeft, faAngleDoubleRight, faCheckSquare, faSquare,
  faStar as faStarReg
} from '@fortawesome/pro-regular-svg-icons'

// Duo-tone
import {
  faBullseyePointer, faBellOn, faHome, faSignOut, faStopwatch as faStopwatchDuo, faUsersClass, faFileChartLine
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  // Solid
  faAd, faArchive, faArrowsAlt, faArrowRight, faBallot, faBookmark,
  faCalendarAlt, faCaretLeft, faCaretRight, faCheck, faCog, faCrown, faFileCsv, faFileSpreadsheet, faFrown,
  faGraduationCap, faLink, faListOl, faMapSigns, faPencil, faPennant, faPeopleArrows, faPlus, faPrint,
  faPuzzlePiece, faQuestion, faQuestionCircle, faSmile, faSpinner, faStar, faStarHalfAlt,
  faStopwatch, faSwords, faSync, faTasks, faTimes, faUndo, faUnlink, faUser, faUserSlash, faUsersCrown,
  faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileImage, faFile,
  // Regular
  faAngleDoubleLeft, faAngleDoubleRight, faCheckSquare, faSquare,
  faStarReg,

  // Duo-tone
  faBullseyePointer, faBellOn, faHome, faSignOut, faStopwatchDuo, faUsersClass, faFileChartLine
)

dom.watch() // This will kick of the replacement of i tags and configure a MutationObserver
