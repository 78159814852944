import { Controller } from 'stimulus'
import createChannel from 'channels/cable'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['data', 'print', 'alert', 'filters', 'sheetTrigger', 'sheetForm']

  initialize() {
    const dataContainer = this.dataTarget
    const filtersContainer = this.filtersTarget
    const print = this.printTarget
    const alert = this.alertTarget

    let reportsController = this

    let id = this.data.get('id')
    let statusOnLoad = this.data.get('statusOnLoad')

    if (statusOnLoad !== 'completed') {

      /*
       *  test coverage
       *  reports-show.test.js
       */
      fetch(`/reports/${this.data.get('id')}.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      })
        .then((response) => {
          return response.json()
        })
        .then((returned) => {
          if (returned.status === 'completed') {
            Turbolinks.visit(`/reports/${this.data.get('id')}`)
          }
        })
    }

    this.reportsChannel = createChannel('ReportsChannel', {
      connected() {
        reportsController.listen()
      },
      received(data) {
        if(data['status'] == 'completed') {
          dataContainer.innerHTML = data['markup']
          print.classList.remove('report-complete-false')
          alert.innerHTML = 'Your report is ready'
          // The remove / add has been swapped here as we don't want to bother
          // with showing the affirmative anymore. We continue to set it though,
          // just in case we change our mind later.
          alert.classList.remove('alert-notice')
          alert.classList.add('alert-hidden')
          filtersContainer.classList.remove('report-complete-false')
        } else if(data['status'] == 'error') {
          dataContainer.innerHTML = ''
          alert.innerHTML = 'An error occured. Please try your report again.'
          alert.classList.add('alert-error')
          alert.classList.remove('alert-notice')
          alert.classList.remove('alert-hidden')
          filtersContainer.classList.remove('report-complete-false')
        }
      }
    })
  }

  /* The controller’s connect() method also calls listen(). The need for two different listen()
     calls has to do with a condition where on a page refresh, the ActionCable connection isn’t
      done loading by the time the controller’s connect() function is called. But on subsequent
      page loads using Turbolinks, the ActionCable subscription is technically still connected,
      so ActionCable won’t call it’s version of connect() again, and the controller will call listen().
  */
  connect() {
    this.listen()
  }

  disconnect() {
    this.reportsChannel.perform('unfollow')
  }

  listen() {
    if (this.reportsChannel) {
      this.reportsChannel.perform('follow', { report_id: this.data.get('id') } )
    }
  }

  generateSheet() {
    /* The sheet form is hidden and triggered from a dummy button that lives outside
       the form. This is purely for cosmetic reasons, as we want the button to live
       in-line in a Bootstrap button group next to the print button
    */
    const sheetForm = this.sheetFormTarget
    const sheetTrigger = this.sheetTriggerTarget

    sheetTrigger.innerHTML = 'XLS generating <i class="fas fa-cog fa-spin"></i>'
    sheetTrigger.classList.add('disabled')

    Rails.fire(sheetForm, 'submit')
  }
}
