/* eslint no-console:0 */
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

const initializeSortable = function() {
  let sortable
  let sortableTables = document.getElementsByClassName('sortable')

  for (var rowset of sortableTables) {
    sortable = Sortable.create(rowset, {
      draggable: ".draggable",
      handle: '.drag',
      animation: 150,
      onSort: function(evt) {
        let item = evt.item;
        let controller = item.dataset.railscontroller
        let id = item.dataset.id;
        let positionData = {
          id: id,
          position: evt.newIndex + 1
        }

        /*
         *  test coverage
         *  controller-update-position.test.js
         */
        fetch('/' + controller + '/update_position', {
          method: 'POST',
          body: JSON.stringify(positionData),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken()
          },
          credentials: 'same-origin'
        })

      }
    })
  }
}

document.addEventListener('turbolinks:load', initializeSortable, false)
document.addEventListener('views:render-sortable', initializeSortable, false)
