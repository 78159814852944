import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Look for controllers inside app/javascripts/packs/controllers/
const application = new Application(document.documentElement, {
  controllerAttribute: "data-stimcontroller",
  actionAttribute: "data-stimaction",
  targetAttribute: "data-stimtarget"
})

application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
