/* eslint no-console:0 */

const initializeDependsOn = function() {
  var dependsOnElements = document.getElementsByClassName('depends-on')

  for (var element of dependsOnElements) {
    let inputId = element.dataset.dependsOnId
    let inputValue = element.dataset.dependsOnValue
    let inputName = element.dataset.dependsOnName

    let input = document.getElementById(inputId)
    let inputsByName = document.getElementsByName(inputName)

    if (!input && !inputsByName) {
      return
    }

    // This is not the most elegant solution but it should handle all
    // odd listener behaviour
    // If input is radio buttons, need to listen to any radio button
    // to see if need to re-evaluate,
    // Also note that multiple elements listening to the same input will
    // override each others event listeners so need to initiaalizeDependsOn
    // not evaluate()
    if (inputsByName && inputsByName.length > 0) {
      for (var nameInput of inputsByName) {
        // TODO: expand
        let event = 'change'
        if (nameInput.type === 'radio' || nameInput.type === 'checkbox') {
          event = 'click'
        }

        nameInput.addEventListener(event, (e) => {
          initializeDependsOn()
        })
      }
    } else {
      // TODO: expand
      let event = 'change'
      if (input.type === 'radio' || input.type === 'checkbox') {
        event = 'click'
      }

      input.addEventListener(event, (e) => {
        initializeDependsOn()
      })
    }

    evaluate(element, input, inputValue)
  }
}

const evaluate = function (element, input, value) {
  // TODO: expand
  switch (input.type) {
    case 'radio':
    case 'checkbox':
      if ((input.checked && value === 'true') || (!input.checked && value === 'false')) {
        element.style.display = ''
      } else {
        element.style.display = 'none'
      }
      break
    case 'select-one':
      if (input.value === value) {
        element.style.display = ''
      } else {
        element.style.display = 'none'
      }
      break
  }
}

document.addEventListener('turbolinks:load', initializeDependsOn, false)
document.addEventListener('refresh_depends_on', initializeDependsOn, false)
