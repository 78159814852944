/* eslint no-console:0 */
import autocomplete from 'autocompleter'
import Rails from '@rails/ujs'

const initializeAutocomplete = function() {
  var autocompleteInputs = document.getElementsByClassName('autocomplete')
  var autocompleteForms = document.getElementsByClassName('prevent-enter-submit')

  for(var form of autocompleteForms) {
    form.addEventListener('keypress', (e) => {
      if (e.keyCode == 13) {
        e.preventDefault()
        return false
      }
    })
  }

  for (var input of autocompleteInputs) {
    let url = input.dataset.autocompleteUrl
    let extraParams = input.dataset.autocompleteExtraParams
    let label = input.dataset.autocompleteLabel
    // Comma separated string
    let terms = input.dataset.autocompleteTerms ? input.dataset.autocompleteTerms.split(',') : ['']
    let id = input.dataset.autocompleteId
    let hiddenInputId = input.dataset.autocompleteHiddenId

    let hiddenInput = document.getElementById(hiddenInputId)



    autocomplete({
      input: input,
      fetch: (text, update) => {
        let searchParams = `.json?search=${text}`
        if (extraParams) {
          searchParams += `&${extraParams}`
        }

        fetch(url + searchParams, {
          method: 'GET',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': Rails.csrfToken()
          },
          credentials: 'same-origin'
        })
          .then((response) => response.json())
          .then((data) => {
            let suggestions = data.filter((n) => {
              return terms.some((term) => {
                // If term not included in json response object, ignore term
                if (!n[term]) { return false }

                return n[term].toLowerCase().includes(text.toLowerCase())
              })
            })
            update(suggestions.map((s) => { return { label: s[label], value: s[id] } }))
          })
          .catch((err) => {
            console.error('Autocomplete error.')
            console.error(err)
          })
      },
      emptyMsg: 'No matching records.',
      minLength: 1,
      debounceWaitMs: 100,
      onSelect: (item) => {
        input.value = item.label
        hiddenInput.value = item.value
      }
    })
  }
}

document.addEventListener('turbolinks:load', initializeAutocomplete, false)
document.addEventListener('refresh_autocomplete', initializeAutocomplete, false)
