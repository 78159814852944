document.addEventListener('turbolinks:load', function() {
  const sectionMarks = document.querySelectorAll('[data-section]')
  const sidebarItems = document.getElementsByClassName('sidebar-item')
  let section = ''

  if (sectionMarks.length > 0) {
    section = sectionMarks[0].dataset.section
  }

  for (var i = 0; i < sidebarItems.length; i++) {
    sidebarItems[i].classList.remove("active")
  }

  let activeItem = document.getElementById(`section-${section}`)
  if (activeItem) {
    activeItem.classList.add('active')
  }

});
