import { Controller } from 'stimulus'
import createChannel from 'channels/cable'

export default class extends Controller {
  static targets = ['alert']

  initialize() {
    let sheetsController = this
    const alert = this.alertTarget

    this.sheetsChannel = createChannel( 'SheetsChannel', {
      connected() {
        sheetsController.listen()
      },
      received(data) {
        if(data['status'] == 'completed') {
          var newPayload = document.createElement('template')
          newPayload.innerHTML = data['markup'].trim()

          var sheetsTrigger = document.getElementById('sheets-trigger')
          sheetsTrigger.parentNode.replaceChild(newPayload.content.firstChild, sheetsTrigger)
          alert.innerHTML = 'Your spreadsheet is ready'
          alert.classList.add('alert-notice')
          alert.classList.remove('alert-hidden')
        } else if(data['status'] == 'error') {
          alert.innerHTML = 'An error occurred. Please try your sheet again.'
          alert.classList.add('alert-error')
          alert.classList.remove('alert-notice')
          alert.classList.remove('alert-hidden')
        }
      }
    })
  }

  /*
    The controller's connect() method also calls listen(). The need for two different listen()
    calls has to do with a condition where on a page refresh, the ActionCable connection isn't
    done loading by the time the controller's connect() function is called. But on subsequent
    page loads using Turbolinks, the ActionCable subscription is technically still connected,
    so ActionCable won't call it's version of connect() again, and the controller will call listen().
  */
  connect() {
    this.listen()
  }

  disconnect() {
    this.sheetsChannel.perform('unfollow')
  }

  listen() {
    if (this.sheetsChannel) {
      this.sheetsChannel.perform('follow', { report_id: this.data.get('id') } )
    }
  }
}
