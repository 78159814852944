sidebarHidden = JSON.parse(localStorage.getItem('sidebar_hidden'))

document.addEventListener('turbolinks:load', function() {
  // We add a class to both the l-page-wrapper and the body
  // The body class is stricty for overriding the bootstrap tooltips
  // which show up on collaposed menu items

  const menuToggleNarrow = document.getElementById('menu-toggle-narrow')
  const menuToggleNarrowIcon = document.getElementById('menu-toggle-narrow-icon')
  const menuToggleWide = document.getElementById('menu-toggle-wide')
  const menuToggleWideIcon = document.getElementById('menu-toggle-wide-icon')

  const pageWrapper = document.getElementById('l-page-wrapper')

  if (menuToggleNarrow) {
    menuToggleNarrow.addEventListener('click', function(e) {
      e.preventDefault()

      pageWrapper.classList.toggle('toggled')
      menuToggleNarrowIcon.classList.toggle('fa-angle-double-left')
      menuToggleNarrowIcon.classList.toggle('fa-angle-double-right')

      if (!sidebarHidden) {
        document.body.classList.remove('toggled-closed')
        document.body.classList.add('toggled-open')
      } else {
        document.body.classList.remove('toggled-open')
        document.body.classList.add('toggled-closed')
      }

      localStorage.setItem('sidebar_hidden', JSON.stringify(false))
    })
  }

  if (menuToggleWide) {
    menuToggleWide.addEventListener('click', function(e) {
      e.preventDefault()
      if (sidebarHidden) {
        pageWrapper.classList.remove('toggled-closed')
        document.body.classList.remove('toggled-closed')
        pageWrapper.classList.add('toggled-open')
        document.body.classList.add('toggled-open')
        menuToggleWideIcon.classList.remove('fa-angle-double-right')
        menuToggleWideIcon.classList.add('fa-angle-double-left')
      } else {
        pageWrapper.classList.remove('toggled-open')
        document.body.classList.remove('toggled-open')
        pageWrapper.classList.add('toggled-closed')
        document.body.classList.add('toggled-closed')
        menuToggleWideIcon.classList.remove('fa-angle-double-left')
        menuToggleWideIcon.classList.add('fa-angle-double-right')
      }
      sidebarHidden = !sidebarHidden
      localStorage.setItem('sidebar_hidden', JSON.stringify(sidebarHidden))
    })
  }

  if (menuToggleWideIcon) {
    if (sidebarHidden && document.body.classList.contains('registered')) {
      pageWrapper.classList.add('toggled-closed')
      document.body.classList.add('toggled-closed')
      menuToggleWideIcon.classList.remove('fa-angle-double-left')
      menuToggleWideIcon.classList.add('fa-angle-double-right')
    } else {
      pageWrapper.classList.add('toggled-open')
      document.body.classList.add('toggled-open')
      menuToggleWideIcon.classList.remove('fa-angle-double-right')
      menuToggleWideIcon.classList.add('fa-angle-double-left')
    }
  }

  setTimeout((function() {
    document.body.classList.remove('animation-preload')
  }), 600)

  // We don't have nested submenus in Upper Rapids right now
  // so we don't need any of the logic regarding show/hide
  // of nested menu items or collapsable submenus. If that's needed
  // go look at the rsvsys codebase.
})
