/* eslint no-console:0 */

import Rails from '@rails/ujs'

class AppAddress {
  initializeProvinceLoad(selectCountry) {
    let countryVal = selectCountry.value
    let province = selectCountry.parentElement.parentElement.getElementsByClassName('province-select')[0]
    let provinceVal = province.value

    return provinceLoad(countryVal, province, provinceVal)
  }
}


const app_address = new AppAddress()

document.addEventListener('turbolinks:load', () => {
  let countryElements = document.querySelectorAll('select.country')

  for(var countryElement of countryElements) {
    app_address.initializeProvinceLoad(countryElement)

    countryElement.addEventListener('change', (e) => {
      let countryVal = e.target.value
      let province = e.target.parentElement.parentElement.getElementsByClassName('province-select')[0]
      return provinceLoad(countryVal, province, '')
    })
  }
}, false)

const createOption = function(value, selected, label) {
  let optionElement = document.createElement('OPTION')
  optionElement.value = value ? value : ''
  optionElement.selected = selected
  optionElement.innerText = label

  return optionElement
}

const provinceLoad = function(countryVal, provinceOriginal, provinceVal) {
  fetch('/provinces', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    credentials: 'same-origin',
    body: JSON.stringify({ province: { country: countryVal } })
  })
    .then((response) => {
      if (!response.ok) {
        return {}
      }

      return response.json()
    })
    .then((data) => {
      if (Object.keys(data).length > 0) {
        let formName = provinceOriginal.name
        let provinceSelect = document.createElement('SELECT')
        provinceSelect.className = 'province-select optional form-control'
        provinceSelect.name = formName
        let selected = false

        if (provinceVal === '') {
          selected = true
        }

        provinceSelect.appendChild(createOption(null, selected, '- None -'))
        Object.keys(data).forEach((key) => {
          selected = false

          if (key === provinceVal) {
            selected = 'selected'
          }

          return provinceSelect.appendChild(createOption(key, selected, data[key]))
        })

        provinceOriginal.after(provinceSelect)
        provinceOriginal.remove()
      } else {
        let formName = provinceOriginal.name
        let provinceText = document.createElement('INPUT')
        provinceText.className = 'province-select optional form-control'
        provinceText.name = formName
        provinceText.value = provinceVal
        provinceText.type = 'text'

        provinceOriginal.after(provinceText)
        provinceOriginal.remove()
      }
    })
    .catch((e) => {
      console.error('Could not fetch provinces', e)
    })
}
