/* eslint no-console:0 */
import Rails from '@rails/ujs'

const initializeRefresh = function() {
  var refresh15Divs = document.getElementsByClassName('refresh-15')

  if (refresh15Divs.length > 0) {
    setInterval(function() {
      window.location.reload()
    }, 15000)
  }
}

document.addEventListener('turbolinks:load', initializeRefresh, false)
