import Flatpickr from 'stimulus-flatpickr'
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  connect() {
    let confirm = this.element.dataset.confirm
    let plugin = []

    if (confirm == 'true') {
      plugin = [new confirmDatePlugin({theme: 'dark', confirmIcon: '<i class="fas fa-check"></i>'})]
    }

    //define locale and global flatpickr settings for all datepickers
    this.config = {
      time_24hr: true,
      altInput: true,
      altFormat: 'D, M j, Y H:i',
      allowInput: true,
      dateFormat: 'Y-m-dTH:i:S',
      // this originally returned 'Z', the ISO date format,
      // but since we're appending the timezone on the server
      // we want it as it appeared local to the browser.
      wrap: true,
      defaultHour: 0,
      minuteIncrement: 1,
      plugins: plugin
    }

    super.connect()
  }

  change(selectedDates, dateStr, instance) {
    let action = instance.element.dataset.changeAction

    if (action == 'reload_slot_definitions') {
      const store = document.getElementById('time-slot-definition-variables')
      store.dataset.firstSlot = dateStr

      var event = new CustomEvent('time_slots:reload')
      document.dispatchEvent(event)
    }
  }
}
