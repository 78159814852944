import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['replacement']

  initialize() {
    const replacement = this.replacementTarget

    var existingLogo = document.getElementById('client-banner-logo')

    if(!existingLogo) {
      var newBannerLogo = document.createElement('img')
      newBannerLogo.src = replacement.src;
      newBannerLogo.id = 'client-banner-logo';

      var originalLogo = document.getElementById('rulesr-banner-logo')
      var bannerLogoLink = document.getElementById('navbar-logo')

      bannerLogoLink.appendChild(newBannerLogo)

      setTimeout(function () {
        originalLogo.classList.remove('show')
        newBannerLogo.classList.add('show')
      }, 1000)
    }
  }
}
