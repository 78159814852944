import { Draggable } from '@shopify/draggable'
import Rails from '@rails/ujs'

const initializeDraggable = function() {
  var draggableContainers = document.getElementsByClassName('draggable-container')

  if (draggableContainers.length === 0) {
    return false
  }

  for (var container of draggableContainers) {
    let uri = container.dataset.dragPath
    let dragMethod = container.dataset.dragMethod
    let dragId = container.dataset.dragId

    let draggable = new Draggable(container, {
      draggable: '.draggable',
      delay: 0
    })

    let lastX = 0
    let lastY = 0

    draggable.on('drag:start', (evt) => {
      let startingTarget = document.elementFromPoint(evt.sensorEvent.clientX, evt.sensorEvent.clientY)

      while (!startingTarget.classList.contains('draggable') && !startingTarget.classList.contains('prevent-drag')) {
        startingTarget = startingTarget.parentElement
      }

      if (startingTarget.classList.contains('prevent-drag')) {
        evt.cancel()
      }
    })

    draggable.on('mirror:move', (evt) => {
      lastX = evt.sensorEvent.clientX
      lastY = evt.sensorEvent.clientY
    })

    draggable.on('drag:stop', (evt) => {
      let endingTarget = document.elementFromPoint(lastX, lastY)

      while (!endingTarget.dataset.dropValue && endingTarget.parentElement) {
        endingTarget = endingTarget.parentElement
      }

      if (endingTarget && endingTarget.dataset.dropValue) {
        let dropKey = endingTarget.dataset.dropKey
        let dropValue = endingTarget.dataset.dropValue
        let dragKey = evt.source.dataset.dragKey
        let dragValue = evt.source.dataset.dragValue

        fetch(`${uri}/${dragId}.js`, {
          method: dragMethod,
          body: `{ \"${dragKey}\": \"${dragValue}\", \"${dropKey}\": \"${dropValue}\" }`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken()
          },
          credentials: 'same-origin'
        })
          .then((response) => {
            return response.text()
          })
          .then((responseScript) => {
            Function(responseScript)()
          })
      }
    })
  }
}

document.addEventListener('turbolinks:load', initializeDraggable, false)
document.addEventListener('views:render-draggable', initializeDraggable, false)
